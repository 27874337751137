import React from "react";
import "../../styles/contact.css";
import { useRef } from "react";
import emailjs from '@emailjs/browser';

import toast, { Toaster } from 'react-hot-toast';

const Contact = ({theme}) => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0gssiwg', 'template_gbnj8sp', form.current, 'cQf9ebWnE3J5G59Uu')
    toast.success("Email sent successfully");

      e.target.reset();
  };
  return (
    <section id="Contact">
      <Toaster/>
      <h2>
        Contact <span className="highlight">Us</span>
      </h2>
      {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, quos!</p> */}
      <div className={theme === "light-theme" ?  "container contact-container" : "container contact-container-dark"}>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Full Name" required />
          <input type="email" name="email" placeholder="Your Email" required />
          <input type="text" name="subject" placeholder="Subject" required />
          <textarea
            name="message"
            cols={30}
            rows={4}
            placeholder="Message"
            required
            defaultValue={""}
          />
          <button className="btn" type="submit">
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
