import React from "react";
import "../../styles/services.css";

const serviceData = [
  {
    icon: "ri-command-line",
    title: "ERP Development",
    desc: "Streamline your business processes with a tailor-made ERP system.We develop solutions that integrate and optimize your entire business workflow.",
  },

  {
    // icon: "ri-apps-line",
    icon: "ri-code-s-slash-line",
    title: "Web Development",
    desc: "Crafting engaging and responsive websites that not only captivate your audience but also align with your business goals. Our expert team ensures a user-friendly experience across all devices.",
  },

  {
    icon: "ri-server-line",
    title: "IT Consulting",
    desc: "Navigate the complex IT landscape with confidence. Our experienced consultants provide strategic guidance, helping you make informed decisions that propel your business forward.",
  },

  {
    icon: "ri-cloud-line",
    title: "Cloud Services",
    desc: "Embrace the scalability and flexibility of the cloud. We offer comprehensive cloud solutions, including migration, management, and optimization, to enhance your business agility.",
  },

  // {
  //   icon: "ri-rocket-line",
  //   title: "Digital Marketing",
  //   desc: "Lorem ipsum dolor, sit amet  elit. quis quae numquam quas ullam harum natus explicabo velit atque!",
  // },
];

const Services = () => {
  return (
    <section id="service">
      <div className="container">
        <div className="services__top-content">
          <h6 className="subtitle">Our Services</h6>
          <h2>Save time managing your business with</h2>
          <h2 className="highlight"> our best services</h2>
        </div>

        <div className="service__item-wrapper">
          {serviceData.map((item, index) => (
            <div className="services__item" key={index}>
              <span className="service__icon">
                <i class={item.icon}></i>
              </span>
              <h3 className="service__title">{item.title}</h3>
              <p className="description">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
