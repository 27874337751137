import React from "react";
import "../../styles/testimonial.css";

import Slider from "react-slick";

import ava01 from "../../images/tarek.png";
import ava02 from "../../images/amine.jpg";
import ava03 from "../../images/ava-3.jpg";

const Testimonial = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };
  return (
    <section>
      <div className="container">
        <div className="slider__content-top">
          <h6 className="subtitle">Testimonials</h6>
          {/* <h2>
            Trusted by more than{" "}
            <span className="highlight">5,000 customers</span>
          </h2> */}
        </div>

        <div className="slider__wrapper">
          <Slider {...settings}>
            <div className="slider__item">
              <p className="description">
                Working with SYT was a game-changer for our
                business. Their team demonstrated exceptional expertise in
                custom software development, delivering a solution that exceeded
                our expectations. The level of professionalism and commitment to
                our project was truly commendable. We look forward to continuing
                our partnership with SYT for future endeavors.
              </p>

              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava01} alt="" />
                </div>

                <div>
                  <h5 className="customer__name">Yezza Tarek</h5>
                  <p className="description">CEO, Workcreation</p>
                </div>
              </div>
            </div>

            <div className="slider__item">
              <p className="description">
                I can't recommend SYT enough for their
                outstanding web development services. They transformed our
                online presence, creating a sleek and responsive website that
                perfectly represents our brand. The team's attention to detail
                and commitment to delivering on time made the entire process
                smooth and enjoyable. Thank you, SYT, for
                elevating our digital presence
              </p>

              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava02} alt="" />
                </div>

                <div>
                  <h5 className="customer__name">Amine Mkacher</h5>
                  <p className="description">Software Developer</p>
                </div>
              </div>
            </div>

            {/* <div className="slider__item">
              <p className="description">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Maiores beatae ipsum, harum magni, dolores labore rerum debitis,
                quae enim minima repudiandae! Veniam voluptate nemo minima quod
                possimus. Iure, nihil vel. Qui aliquam minus architecto sapiente
                facere sint id temporibus veniam!
              </p>

              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava03} alt="" />
                </div>

                <div>
                  <h5 className="customer__name">William Cooper</h5>
                  <p className="description">Sr. Product Designer</p>
                </div>
              </div>
            </div> */}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
