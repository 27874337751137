import React from "react";
import "../../styles/about.css";

import aboutImg from "../../images/about-us.jpg";

const chooseData = [
  {
    icon: "ri-wifi-line",
    title: "Expertise",
    desc: "Our team comprises seasoned professionals with a wealth of experience in diverse IT domains. We bring a depth of knowledge that ensures we understand your unique challenges and deliver solutions that align seamlessly with your goals.",
  },

  {
    icon: "ri-team-line",
    title: "Innovation",
    desc: "Embrace the future with confidence. At SYT, we pride ourselves on our forward-thinking approach. We stay at the forefront of technological advancements, ensuring that your solutions are not just current but positioned for sustained success.",
  },
  {
    icon: "ri-customer-service-2-line",
    title: "Quality Assurance",
    desc: "Excellence is not just a goal; it's our standard. Rigorous testing and quality assurance processes are ingrained in our workflow, ensuring that the solutions we deliver are reliable, secure, and high-performing.",
  },
];

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <div className="about__wrapper">
          <div className="about__content">
            <h6 className="subtitle">Why choose us</h6>
            <h2>Guiding Businesses Through Financial Fortitude:</h2>
            <h2 className="highlight">  Your Trusted IT Partner</h2>
            <p className="description about__content-desc">
              In an ever-evolving digital landscape, choosing the right IT
              partner is crucial. SYT stands out for more than just our
              technical expertise. We believe in forging lasting partnerships.
            </p>

            <div className="choose__item-wrapper">
              {chooseData.map((item, index) => (
                <div className="choose__us-item" key={index}>
                  <span className="choose__us-icon">
                    <i class={item.icon}></i>
                  </span>
                  <div>
                    <h4 className="choose__us-title">{item.title}</h4>
                    <p className="description">{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="about__img">
            <img src={aboutImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
