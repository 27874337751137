import React from "react";
import "../../styles/newsletter.css";
import { useRef } from "react";
import emailjs from '@emailjs/browser';

import toast, { Toaster } from 'react-hot-toast';

const Newsletter = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0gssiwg', 'template_xsgy73p', form.current, 'cQf9ebWnE3J5G59Uu')
    toast.success("Your mail is sent successfully");

      e.target.reset();
  };
  return (
    <section className="newsletter">
      <Toaster/>
      <div className="container">
        <div className="newsletter__wrapper">
          <div className="newsletter__content">
            <h6 className="subtitle">Let's work</h6>
            <h2>
              Explore the <span className="highlight">hidden</span> ideas and
              subscribe!
            </h2>
          </div>

          <form className="newsletter__form"  ref={form} onSubmit={sendEmail}>
            <input type="email" name="email" placeholder="Email" />
            <button className="secondary__btn subscribe__btn" type="submit">
              Subscribe Now
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Newsletter;
